const HTTP_REGEX =
  /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export function isValidUrl(url: string) {
  return HTTP_REGEX.test(url);
}

export const addRequiredValidation = (
  message = "This question is required",
) => ({
  type: "required",
  message,
});

export const addRequiredCountValidation = ({
  min,
  max,
}: {
  min?: number;
  max?: number;
}) => {
  const validation: {
    type: string;
    message: string;
    min?: number;
    max?: number;
  } = {
    type: "require_count",
    // this isn't actually being used currently, validation message will be generated on STS
    message: "This field is required",
  };

  if (typeof min === "number" && min > 0) {
    validation.min = min;
  }

  if (typeof max === "number" && max > 0) {
    validation.max = max;
  }

  return validation;
};

// fake the global window object here, so we can run our check below without being in a browser/node env
// Need node >= 16 for randomUUID or a browser environment
declare let window: {
  crypto: {
    randomUUID: () => `${string}-${string}-${string}-${string}-${string}`;
  };
};

/**
 * Generate a UUID portably.  Slightly tweaked from the credited
 * implementation.
 *
 * Author: Abhishek Dutta, 12 June 2020
 * License: CC0 (https://creativecommons.org/choose/zero/)
 */
export function generateUUID() {
  const temp_url = URL.createObjectURL(new Blob([]));
  const uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  return uuid.substring(uuid.lastIndexOf("/") + 1);
}
