import { Button } from "melodies-source/Button";
import styled from "styled-components";
import {
  useDataContext,
  useRule,
  validateModule,
  useAnalytics,
  useSurveyContext,
} from "Components";
import { useActionColor } from "../../color";
import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponses } from "@max/common/functions/setfan";

export const SubmitButton = ({
  style,
  content,
  enable,
  parent,
  href,
  id,
  children,
  ...props
}: SurveyConfigModule) => {
  const { complement: textColor } = useActionColor();

  const { id: surveyId, survey } = useSurveyContext();
  const {
    data,
    setData,
    onSubmit,
    view,
    setView,
    showValidation,
    setShowValidation,
    isSubmissionPage,
  } = useDataContext();
  const { logClick } = useAnalytics();
  const d = data?.[id];
  const enabledRule = useRule(enable);
  const enabled = enable
    ? enabledRule
    : validateModule(parent, data, surveyId, survey.localProfiling);

  const handleClick = () => {
    if (!enabled) {
      return setShowValidation(true);
    }

    setShowValidation(false);

    logClick({
      action: "submit",
      label: id,
      view,
    });

    let update = {
      ...data,
      completeSurveys: [],
      [id]: {
        ...d,
        value: true,
      },
    };

    if (isSubmissionPage) {
      update = {
        ...update,
        completeSurveys: [...(update.completeSurveys || []), surveyId],
      };
      onSubmit(update as SurveyResponses);
    }

    setData(update);

    if (!href) {
      setView(view + 1);
      window.scrollTo(0, 0);
    }

    if (href) {
      setTimeout(() => {
        window.location.href = href;
      }, 50);
    }
  };

  return (
    <>
      {!enabled && showValidation && (
        <ValidationError>
          Please correct the missing or incomplete information above and hit
          submit again
        </ValidationError>
      )}
      {/*@ts-ignore */}
      <ButtonAdjusted
        textColor={textColor}
        style={style}
        onClick={handleClick}
        {...props}
        children={isSubmissionPage ? "Submit" : children || "Continue"}
      />
    </>
  );
};

const ButtonAdjusted = styled(Button)<{ textColor: string }>`
  width: auto;
  background-color: var(--max-action);
  color: ${({ textColor }) => textColor};

  :hover {
    background-color: var(--max-action);
    opacity: 0.9;
  }
`;

const ValidationError = styled.div`
  background: #e3463b;
  padding: 10px;
  margin-bottom: 31px;
  border-radius: 6px;
  color: #fff;
`;
