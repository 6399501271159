import {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  FC,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { firebaseApp } from "./Firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Spinner } from "melodies-source/Spinner";
import {
  DEFAULT_PROFILING_CATEGORIES,
  ProfilingCategory,
  SurveyConfig,
  SurveyConfigModule,
  SurveyConfigProfilingModule,
  defaultProfiling,
} from "@max/common/setfan";

// firestore does not support nested arrays so we store a flat profiling override object in firestore
// and nest them in memory
type SurveyConfigLocal = SurveyConfig & {
  localProfiling: SurveyConfigProfilingModule[][];
};

interface SurveyContextProps {
  survey?: SurveyConfigLocal;
  rawSurvey?: SurveyConfig;
  id: string;
  progressiveProfiling?: Record<string, true>;
  loadingProfiling: boolean;
  email?: string;
  setEmail?: Dispatch<SetStateAction<string>>;
  isEmailInjected?: boolean;
  setIsEmailInjected: Dispatch<SetStateAction<boolean>>;
}

const SurveyContext = createContext<SurveyContextProps>(
  {} as SurveyContextProps,
);

export const SurveyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [survey, setSurvey] = useState<SurveyConfigLocal>();
  const [email, setEmail] = useState("");
  const [isEmailInjected, setIsEmailInjected] = useState(false);

  const params = useParams();
  const db = getFirestore(firebaseApp);

  const docRef = params.surveyId
    ? (doc(
        db,
        `sts3_surveys`,
        params?.surveyId ?? "",
        `versions`,
        `prod`,
      ) as DocumentReference<SurveyConfig>)
    : undefined;
  const [rawSurvey, loadingRawSurvey] = useDocumentData(docRef);

  const [hashed, setHashed] = useState<string>();

  useEffect(() => {
    if (!email) {
      return;
    }

    (async () => {
      const msgUint8 = new TextEncoder().encode(email?.toLowerCase());
      const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
      setHashed(hashHex);
    })();
  }, [email]);

  const [profiling, loadingProfiling] = useDocumentData(
    doc(
      getFirestore(firebaseApp),
      `artist_groups/${survey?.artistGroupId}/progressive_profiles/${hashed}`,
    ) as DocumentReference<{ responses: Record<string, true> }>,
  );

  useEffect(() => {
    if (!rawSurvey) {
      return;
    }
    const restrictedProfiling = mergeProfiling(rawSurvey);

    const restrictedSurvey = restrictProfilingBlocks(
      restrictedProfiling,
      profiling?.responses,
    );
    // due to the new profiling pagination, we might end up with pages which only contain
    // profiling blocks from the survey config editor. if we reduce the number of profiling
    // blocks, we might end up with empty pages and a continue button. this will
    // eliminate those
    const prunedSurvey = removeEmptyPages(restrictedSurvey);
    // going forward, since we place the opt in block dynamically based on available pages above,
    // we have to append it after the survey pages have been pruned.
    setSurvey(appendOptIns(prunedSurvey));
  }, [rawSurvey, profiling?.responses]);

  const value: SurveyContextProps = {
    survey,
    rawSurvey,
    progressiveProfiling: profiling?.responses,
    loadingProfiling,
    id: params?.surveyId,
    email,
    setEmail,
    isEmailInjected,
    setIsEmailInjected,
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingRawSurvey && !rawSurvey) {
      navigate("/", { replace: true });
    }
  }, [loadingRawSurvey, rawSurvey, navigate]);

  if (!value?.survey || !value?.id) {
    return <Spinner style={{ minHeight: "100vh" }} color="#d3d3d3" />;
  }

  return (
    <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>
  );
};

export const useSurveyContext = () => useContext(SurveyContext);

/**
 * survey configs contain an optional override for profiling categories (preface text, etc) and an
 * optional list of profile category exclusions that an artist selects.at survey config load, we
 * first apply any profiling overrides to profile categories. then, we use the list of profiling exclusions
 * in the config to filter out "unavailable" categories so a user will never be shown them while taking this
 * survey.
 * @param survey
 * @returns
 */
const mergeProfiling = (rawSurvey?: SurveyConfig) => {
  if (!rawSurvey) {
    return null;
  }

  let localProfiling = [...defaultProfiling];

  if (rawSurvey?.profiling) {
    localProfiling = defaultProfiling.map((categoryGroup) => {
      return categoryGroup.map((profilingCategory) => {
        const override = rawSurvey.profiling.find(
          (savedOverride) => savedOverride.id === profilingCategory.id,
        );
        // if profiling in survey config has this profiling category, override with data
        // otherwise, just return the default profile category
        return override ?? profilingCategory;
      });
    });
  }

  if (rawSurvey?.profilingExclusions) {
    localProfiling = localProfiling.map((profilingCategory) =>
      profilingCategory.filter(
        (profCat) => !rawSurvey.profilingExclusions.includes(profCat.id),
      ),
    );
  }

  return { ...rawSurvey, localProfiling };
};

/**
 * currently, profiling blocks are inserted into the survey config at survey creation/update.
 * since we generally do not conditionally render profiling blocks (with the exception of `forced` categories),
 * we have to prune profiling blocks we will not use in the event that there are not enough remaining categories
 * to fill those profiling blocks due to progressive profiling data or cross dependent categories -
 * such as the demographics and alcohol categories, as you need user age information to show them alcohol
 * related questions.
 *
 * depending on the previously answered questions for this user and any exclusions on the config, we calculate
 * the total number of remaining categories and prune profiling blocks off the end of the survey until we can
 * fill all remaining profiling modules (leaving 1 additional for the `completed` profiling category)
 * @param survey
 * @param savedData
 * @returns
 */
const restrictProfilingBlocks = (
  localSurvey: SurveyConfigLocal,
  savedData: Record<string, true>,
): SurveyConfigLocal => {
  if (!localSurvey?.pages?.length) {
    return localSurvey;
  }

  let surveyProfilingCount = countProfilingBlocks(localSurvey.pages);

  // while the default profiling categories do not include the `completed` category,
  // it does contain the email profiling question, which we treat as a special case.
  // so taking the length of this array for determining blocks to delete works the same.
  let remainingCategories = DEFAULT_PROFILING_CATEGORIES.filter(
    (profCat) => !(localSurvey?.profilingExclusions ?? []).includes(profCat),
  );

  if (savedData) {
    Object.keys(savedData).forEach((key) => {
      if (DEFAULT_PROFILING_CATEGORIES.includes(key as ProfilingCategory)) {
        const indexToRemove = remainingCategories.indexOf(
          key as ProfilingCategory,
        );
        if (indexToRemove > -1) {
          remainingCategories.splice(indexToRemove, 1);
        }
      }
    });
  }

  // if ofAge flag is not set to true, delete alcohol profiling module
  if (!savedData?.["ofAge"]) {
    const indexOfAlcohol = remainingCategories.indexOf(
      "profilecategory.alcohol",
    );
    if (indexOfAlcohol > -1) {
      remainingCategories.splice(indexOfAlcohol, 1);
    }
  }

  const diff = surveyProfilingCount - remainingCategories.length;

  if (diff <= 0) {
    return localSurvey;
  }

  return removeProfilingBlocks(localSurvey, diff);
};

const countProfilingBlocks = (surveyPages: SurveyConfigModule[]) => {
  const diveCount = (mod: SurveyConfigModule, counter: { value: number }) => {
    if (mod.type === "profiling" && !mod.force) {
      counter.value += 1;
    }

    if (mod.modules?.length) {
      mod.modules.forEach((m) => diveCount(m, counter));
    }
  };

  const counter = { value: 0 };

  surveyPages.forEach((surveyPage) => {
    if (surveyPage.modules.length) {
      surveyPage.modules.forEach((mod) => diveCount(mod, counter));
    }
  });
  return counter.value;
};

/**
 * reverse depth search to remove profiling blocks from end
 * @param surveyPages
 * @param startIndex
 * @param lowestDeleteIndex
 */
const removeProfilingBlocks = (
  survey: SurveyConfigLocal,
  removeCount: number,
) => {
  // pass object so we can delete by index as we crawl
  const diveDeleteProfilingBlocks = (
    modules: SurveyConfigModule[],
    count: { toRemove: number },
  ) => {
    for (let i = modules.length - 1; i >= 0; i--) {
      if (modules[i].modules?.length) {
        diveDeleteProfilingBlocks(modules[i].modules, count);
      }

      if (modules[i].type === "profiling" && !modules[i].force) {
        // remove if profile block is "extra". Do not delete the final block as we always
        // want to show a minimum of 11
        if (count.toRemove > 0 && modules[i].index > 1) {
          modules.splice(i, 1);
        }
        count.toRemove -= 1;
      }
    }
  };

  const count = { toRemove: removeCount };

  // when removing profiling blocks, we want to work backwards to remove the
  // last profiling block first (highest index)
  for (let i = survey.pages.length - 1; i >= 0; i--) {
    diveDeleteProfilingBlocks(survey.pages[i].modules, count);
  }

  return survey;
};

// removes empty pages in place
const removeEmptyPages = (surveyConfig: SurveyConfigLocal) => {
  const diveCountModules = (
    module: SurveyConfigModule,
    meta: { count: number },
  ) => {
    switch (module.type) {
      case "page":
      case "container":
      case "card":
        module.modules?.forEach((mod) => diveCountModules(mod, meta));
        break;
      case "confirm":
      case "progress":
      case "submit":
      case "text":
        break;
      default:
        meta.count += 1;
    }
  };

  const emptyPageIndices = [];
  surveyConfig.pages.forEach((page, idx) => {
    const moduleMeta = { count: 0 };
    diveCountModules(page, moduleMeta);
    if (!moduleMeta.count && idx !== surveyConfig.pages.length - 1) {
      emptyPageIndices.push(idx);
    }
  });

  emptyPageIndices.reverse().forEach((idx) => {
    surveyConfig.pages.splice(idx, 1);
  });

  return surveyConfig;
};

/**
 * optIns are injected dynamically depending on what the last page before submission is.
 * this can change depending on progressive profiling information or other demographic information as
 * a user takes a survey. this function runs on data/survey changes and appends the optIn right before
 * the submit button on the second to last page (if the optIns exist in the survey config)
 * @param survey
 * @returns
 */
const appendOptIns = (survey: SurveyConfigLocal) => {
  // old surveys won't have optIns in the config. however, those old surveys already
  // have the `confirm` block in the config page modules, so it will render as normal
  if (!survey?.optIns) {
    return survey;
  }

  const submitPageModules =
    survey.pages[survey.pages.length - 2]?.modules?.[1]?.modules?.[0]?.modules;

  const submitIndex = submitPageModules.findIndex(
    (mod) => mod.type === "submit",
  );

  if (
    submitIndex > 0 &&
    submitPageModules[submitIndex - 1].type !== "confirm"
  ) {
    submitPageModules.splice(submitIndex, 0, survey.optIns);
  }

  return survey;
};
