import { DateTime } from "luxon";
import { CSSServerProperties } from "../creator";
import { FieldValue, Timestamp } from "firebase/firestore";

export type DisplayOrder = { [x: string]: { display: number } };

const ModuleKeys = [
  "Autocomplete",
  "container",
  "card",
  "confirm",
  "Dropdown",
  "EmailQuestion",
  "Html",
  "image",
  "ImageQuestion",
  "OfAge",
  "MultipleSelectQuestion",
  "NameQuestion",
  "page",
  "PhoneQuestion",
  "profiling",
  "progress",
  "RankedQuestion",
  "SingleSelectQuestion",
  "SongsQuestion",
  "socialmedia",
  "SongsQuestion",
  "submit",
  "text",
  "textQuestion",
  "TextQuestion",
  "TypeaheadQuestion",
  "YearBorn",
  "ZipQuestion",
  "CountryQuestion",
] as const;
export type ModuleType = (typeof ModuleKeys)[number];

export type SurveyOptIn = {
  id: string;
  url?: string;
  entityName?: string;
  htmlLabel?: string;
  required?: boolean;
};

export type Config = {
  actionColor?: string;
  artistName?: string;
  artistPolicy?: string;
  backgroundImage?: string;
  backgroundColor?: string;
  globalStyles: string;
  optIns?: SurveyOptIn[];
  profilingBlocks?: number;
};

// alternative use (album/songs) - label is the album name, src is an image label for the album
// songs are the songs of the album with the label being the song name
export type SurveyConfigOption = {
  id: string;
  // for calculating fan profile values
  adj?: number;
  description?: string;
  label?: string;
  src?: string;
  htmlLabel?: string;
  // for user defined single and multi select options
  userDefined?: boolean;
  options?: SurveyConfigOption[];
};

// thanks TS
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SurveyConfigModuleParent extends SurveyConfigModule {}

export interface SurveyConfigModule {
  actionRequired?: boolean;
  adj?: number;
  autocomplete?: unknown;
  backgroundColor?: string;
  children?: string;
  color?: string;
  compact?: boolean;
  complete?: boolean;
  content?: string;
  displayOrder?: "asc" | "desc";
  editable?: boolean;
  enable?: { any?: Rule[]; all?: Rule[] };
  facebook?: string;
  // if you want to force a particular profiling block by id, pass the id here
  force?: ProfilingCategory;
  header?: { children: string };
  /**
   * A prop to prevent showing a module to a user.
   * example: we want to inject a value from a query parameter as a question/answer pair without
   * the end user knowing (setlive event id, etc)
   */
  hidden?: boolean;
  href?: string;
  id?: string;
  image?: {
    src: string;
    align: "left" | "center";
    style?: CSSServerProperties;
  };
  index?: number;
  instagram?: string;
  label?: string;
  modules?: SurveyConfigModule[];
  multiline?: boolean;
  noBorder?: boolean;
  options?: SurveyConfigOption[];
  parent?: SurveyConfigModuleParent;
  rankLow?: string;
  rankHigh?: string;
  rankRange?: number;
  randomizeOptions?: boolean;
  showLabels?: boolean;
  snapchat?: string;
  src?: string;
  style?: CSSServerProperties;
  // a way to identify specific modules for importing into templated survey editors
  tag?: string;
  twitter?: string;
  type: ModuleType;
  url?: string;
  validation?: Validation;
  variant?: string;
  variants?: { mobile: TextVariant; desktop: TextVariant };
  visibility?: { any?: Rule[]; all?: Rule[] };
}

/**
 * A ProfilingModule represents a profiling category. A category can contain multiple questions.
 */
export type SurveyConfigProfilingModule = {
  id: string;
  // legacy when we were storing profiling in total in the firestore (no 2d arrays)
  group?: number;
  preface?: {
    content: string;
    variants?: { mobile: TextVariant; desktop: TextVariant };
    type: ModuleType;
  }[];
  questions: SurveyConfigModule[];
  tags?: string[];
};

type Rule = {
  type: ModuleType & "questionAnswered";
  id: string;
  value: { any?: string[]; all?: string[] };
};

/**
 * @path {/sts3_surveys/<surveyId>/versions/<versionId>}
 */
export type SurveyConfig = {
  artistGroupId: string;
  artistName: string;
  config: Config;
  createdAt: FieldValue;
  meta: [
    { title: { value: string } },
    { meta: { props: { property: string; content: string } } },
  ];
  /**
   * already built optIns ready for rendering by the client
   */
  optIns?: SurveyConfigModule;
  pages: SurveyConfigModule[];
  profiling?: SurveyConfigProfilingModule[];
  profilingExclusions?: string[];
  statusPage?: SurveyConfigModule;
};

//
//  TODO: This is incomplete
//
export interface SurveyTypesenseDocument {
  id: string;
  artistGroupId: string;
  consumerResponses?: number;
  createdAt: number;
  demographicResponses?: number;
  description?: string;
  imageUrl?: string;
  isDeleted?: boolean;
  lastResponse: number;
  responses: number;
  surveyId: string;
  status: SurveyStatus;
  surveyType?: number;
  title: string;
}

const SurveyStatuses = [
  "draft",
  "live",
  "completed",
  "cancelled",
  "published",
  "unpublished",
  "deleted",
] as const;

/**
 * the status of the survey.
 * draft: the survey is actively being edited
 * live: the survey is shared with fans
 * completed: the survey has collected responses and is no longer active
 * cancelled: the survey has collected responses but has been cancelled by the artist
 *
 * NOTE: "published" and "unpublished" statuses are deprecated but left for legacy purposes
 */
export type SurveyStatus = (typeof SurveyStatuses)[number];

/**
 * @path {/sts3_surveys/<surveyId>}
 */
export interface SurveyRootDoc {
  artistGroupId: string;
  artistName: string;
  consumerResponses?: number;
  createdAt: Timestamp;
  demographicResponses?: number;
  displayOrder?: DisplayOrder;
  firstResponse?: DateTime;
  internalTitle?: string;
  lastResponse?: DateTime;
  totalResponses?: number;
  sweeps?: {
    rulesUrl?: string;
    endDate?: Timestamp;
    prize?: string;
    prizeValue?: number;
  };
  status: SurveyStatus;
  title?: string;
  updatedAt?: DateTime;
}

const SweepsStatus = [
  "initialized",
  "sampled",
  "replacing",
  "cancelled",
  "error",
] as const;

export interface SweepsDocument {
  surveyId: string;
  numberOfWinners: number;
  limitToUS: boolean;
  winners: string[];
  cancelledAt?: DateTime;
  createdAt: DateTime;
  winnersSampledAt?: DateTime;
  fanExclusions?: string[];
  removedFans?: string[];
  status: (typeof SweepsStatus)[number];
}

const PC = "profilecategory";
export const DEFAULT_PROFILING_CATEGORIES = [
  `${PC}.email`,
  `${PC}.core`,
  `${PC}.demographics`,
  `${PC}.fooddrink`,
  `${PC}.alcohol`,
  `${PC}.clothing`,
  `${PC}.vehicle`,
  `${PC}.entertainment`,
] as const;

export type ProfilingCategory = (typeof DEFAULT_PROFILING_CATEGORIES)[number];

export const defaultProfiling: SurveyConfigProfilingModule[][] = [
  [
    {
      id: "profilecategory.email",
      questions: [
        {
          type: "EmailQuestion",
          id: "email",
          header: { children: "Enter your Email" },
          validation: {
            type: "email",
            message: "Please enter your email",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.core",
      questions: [
        {
          type: "NameQuestion",
          id: "name",
          header: { children: "Please enter your name" },
          validation: {
            type: "name",
            message: "Please enter your name",
          },
        },
        {
          type: "PhoneQuestion",
          id: "phone",
          header: { children: "Your Phone" },
          validation: {
            type: "required",
            message: "Please enter your phone",
          },
        },
        {
          type: "ZipQuestion",
          id: "zip",
          validation: {
            type: "zip",
            message: "Please enter your zip code",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.demographics",
      questions: [
        {
          type: "YearBorn",
          id: "profiling.yearborn",
          header: {
            children: "What year were you born?",
          },
          validation: {
            type: "yearborn",
            message: "Enter year as YYYY format (e.g. 1987)",
          },
        },
        {
          type: "SingleSelectQuestion",
          id: "profiling.gender",
          header: {
            children: "Are you?",
          },
          options: [
            { id: "female", label: "Female" },
            { id: "male", label: "Male" },
            { id: "nonbinary", label: "Non-binary" },
            { id: "notsay", label: "Prefer not to say" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.musicsource",
          header: {
            children: "How do you most often listen to music (Choose up to 3)?",
          },
          options: [
            { id: "radio", label: "Radio" },
            { id: "vinyl", label: "Vinyl/Records" },
            { id: "tapes", label: "Tapes" },
            { id: "cd", label: "CDs" },
            { id: "amazon", label: "Amazon Music Unlimited" },
            { id: "apple", label: "Apple Music" },
            { id: "pandorafree", label: "Pandora (Free)" },
            { id: "pandorapaid", label: "Pandora (Paid Subscription)" },
            { id: "spotifyfree", label: "Spotify (Free)" },
            { id: "spotifypaid", label: "Spotify (Paid Subscription)" },
            { id: "tidal", label: "Tidal" },
            { id: "yt", label: "YouTube (The Main Website/App)" },
            { id: "ytm", label: "YouTube (Music Streaming Service)" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "require_count",
            max: 3,
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.socialusage",
          header: {
            children: "Which of these do you use daily?",
          },
          options: [
            { id: "bereal", label: "BeReal" },
            { id: "discord", label: "Discord" },
            { id: "facebook", label: "Facebook" },
            { id: "instagram", label: "Instagram" },
            { id: "snapchat", label: "Snapchat" },
            { id: "tiktok", label: "TikTok" },
            { id: "twitch", label: "Twitch" },
            { id: "twitter", label: "Twitter" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.fooddrink",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.eaten",
          header: {
            children: "Which of the following have you eaten in the last week?",
          },
          options: [
            {
              id: "casual",
              label:
                "A meal from a casual sit-down restaurant (dine-in or takeout)",
            },
            {
              id: "fine",
              label:
                "A meal from a fine dining restaurant (dine-in or takeout)",
            },
            {
              id: "delivery",
              label: "Restaurant food delivered via a service like UberEats",
            },
            { id: "mealkit", label: "A meal kit/service I bought" },
            {
              id: "fastfood",
              label:
                "A meal from a fast food restaurant (dine-in, drive-through or takeout",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.groceries",
          header: {
            children:
              "When you’re getting groceries which are important to you?",
          },
          options: [
            {
              id: "craving",
              label: "What I’m craving",
            },
            {
              id: "healthy",
              label: "How healthy it is",
            },
            {
              id: "price",
              label: "What's on sale/affordable",
            },
            { id: "local", label: "What is local or organic" },
            { id: "deliver", label: "What can be delivered" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.drinks",
          header: {
            children: "Which of these do you drink on a regular basis?",
          },
          options: [
            {
              id: "dietsoft",
              label: "Diet soft drinks",
            },
            {
              id: "water",
              label: "Bottled/flavored water",
            },
            {
              id: "tea",
              label: "Tea",
            },
            { id: "juice", label: "Fruit or vegetable juice" },
            { id: "sports", label: "Sports drinks" },
            { id: "soft", label: "Soft drinks (non-diet)" },
            { id: "coffee", label: "Coffee/coffee drinks" },
            { id: "energy", label: "Energy drinks" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.foodpartner",
          header: {
            children:
              "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.alcohol",
      tags: ["alcohol"],
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.alcoholchoice",
          header: {
            children:
              "When you’re drinking, which of these do you tend to drink (check all that apply)?",
          },
          options: [
            {
              id: "beer",
              label: "Regular (not light, not craft/import) beer ",
            },
            {
              id: "lightbeer",
              label: "Light beer",
            },
            {
              id: "importedbeer",
              label: "Imported or Craft Beer",
            },
            { id: "wine", label: "Wine" },
            { id: "vodka", label: "Vodka (or Vodka Mixed Drinks)" },
            {
              id: "whiskey",
              label: "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)",
            },
            { id: "rum", label: "Rum (or Rum Mixed Drinks)" },
            { id: "gin", label: "Gin (or Gin Mixed Drinks)" },
            { id: "tequila", label: "Tequila (or Tequila Mixed Drinks)" },
            { id: "premiumliquor", label: "A premium liquor" },
            { id: "cheapliquor", label: "Budget/cheap liquor" },
            { id: "cider", label: "Hard Cider or Lemonade" },
            { id: "seltzer", label: "Hard Seltzer" },
            { id: "none", label: "I don’t drink alcohol" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          id: "profiling.alcoholpartner",
          type: "TextQuestion",
          header: {
            children:
              "What beer, wine or alcohol brands could you see {artist} partnering with?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.clothing",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.buyclothing",
          header: {
            children:
              "Which of these do you regularly buy clothes/accessories from?",
          },
          options: [
            {
              id: "discount",
              label: "Discount clothing stores",
            },
            {
              id: "mainstream",
              label: "Mainstream mall clothing stores",
            },
            {
              id: "highend",
              label: "High end fashion stores",
            },
            {
              id: "onlinemainstream",
              label: "The online versions of mainstream mall stores",
            },
            { id: "walmart", label: "A store like WalMart or Target" },
            {
              id: "online",
              label: "Online-only stores",
            },
            {
              id: "subscription",
              label: "Online subscription services",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.artistclothing",
          header: {
            children:
              "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}",
          },
          validation: {
            type: "required",
            message: "Please enter a response",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.beauty",
          header: {
            children:
              "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?",
          },
          options: [
            {
              id: "economical",
              label: "I usually look for the most economical option",
            },
            {
              id: "premium",
              label: "I prefer to buy products from premium brands",
            },
            {
              id: "ethics",
              label:
                "The company’s values are an important factor in my decision",
            },
            {
              id: "natural",
              label:
                "I prefer cosmetics/personal care products that are natural or organic",
            },
            {
              id: "subscription",
              label:
                "I use a subscription service (like Dollar Shave Club or Birchbox)",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
    {
      id: "profilecategory.vehicle",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehicle",
          header: {
            children: "Which types of vehicle would you consider getting next?",
          },
          options: [
            { id: "electric", label: "Electric" },
            { id: "hybrid", label: "Hybrid" },
            { id: "suv", label: "SUV" },
            { id: "pickup", label: "Pickup Truck" },
            { id: "car", label: "Small Car" },
            { id: "hatchback", label: "Hatchback" },
            { id: "motorcycle", label: "Motorcycle" },
            { id: "none", label: "I don’t drive" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehiclecost",
          header: {
            children:
              "Which of these are likely to describe your next vehicle?",
          },
          options: [
            { id: "budget", label: "Budget/inexpensive" },
            { id: "highend", label: "Full-Featured/High-End Trim" },
            { id: "used", label: "Used" },
            { id: "new", label: "New" },
            { id: "american", label: "American" },
            { id: "Import", label: "Import" },
            { id: "none", label: "I don’t drive" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          id: "profiling.vehiclepartner",
          type: "Autocomplete",
          options: [
            {
              id: "Acura",
              label: "Acura",
            },
            {
              id: "Alfa",
              label: "Alfa",
            },
            {
              id: "Aston Martin",
              label: "Aston Martin",
            },
            {
              id: "Audi",
              label: "Audi",
            },
            {
              id: "Bentley",
              label: "Bentley",
            },
            {
              id: "BMW",
              label: "BMW",
            },
            {
              id: "Buick",
              label: "Buick",
            },
            {
              id: "Cadillac",
              label: "Cadillac",
            },
            {
              id: "Chevy/Chevrolet",
              label: "Chevy/Chevrolet",
            },
            {
              id: "Chrysler",
              label: "Chrysler",
            },
            {
              id: "Dodge",
              label: "Dodge",
            },
            {
              id: "Ferrari",
              label: "Ferrari",
            },
            {
              id: "FIAT",
              label: "FIAT",
            },
            {
              id: "Ford",
              label: "Ford",
            },
            {
              id: "Genesis",
              label: "Genesis",
            },
            {
              id: "GMC",
              label: "GMC",
            },
            {
              id: "Honda",
              label: "Honda",
            },
            {
              id: "HUMMER",
              label: "HUMMER",
            },
            {
              id: "Hyundai",
              label: "Hyundai",
            },
            {
              id: "INFINITI",
              label: "INFINITI",
            },
            {
              id: "Isuzu",
              label: "Isuzu",
            },
            {
              id: "Jaguar",
              label: "Jaguar",
            },
            {
              id: "Jeep",
              label: "Jeep",
            },
            {
              id: "Kia",
              label: "Kia",
            },
            {
              id: "Lamborghini",
              label: "Lamborghini",
            },
            {
              id: "Land Rover",
              label: "Land Rover",
            },
            {
              id: "Lexus",
              label: "Lexus",
            },
            {
              id: "Lincoln",
              label: "Lincoln",
            },
            {
              id: "Maserati",
              label: "Maserati",
            },
            {
              id: "MAZDA",
              label: "MAZDA",
            },
            {
              id: "Mercedes-Benz",
              label: "Mercedes-Benz",
            },
            {
              id: "Mercury",
              label: "Mercury",
            },
            {
              id: "MINI",
              label: "MINI",
            },
            {
              id: "Mitsubishi",
              label: "Mitsubishi",
            },
            {
              id: "NISSAN",
              label: "NISSAN",
            },
            {
              id: "Porsche",
              label: "Porsche",
            },
            {
              id: "RAM",
              label: "RAM",
            },
            {
              id: "Rolls-Royce",
              label: "Rolls-Royce",
            },
            {
              id: "Saab",
              label: "Saab",
            },
            {
              id: "Saturn",
              label: "Saturn",
            },
            {
              id: "Scion",
              label: "Scion",
            },
            {
              id: "smart",
              label: "smart",
            },
            {
              id: "Subaru",
              label: "Subaru",
            },
            {
              id: "Suzuki",
              label: "Suzuki",
            },
            {
              id: "Tesla",
              label: "Tesla",
            },
            {
              id: "Toyota",
              label: "Toyota",
            },
            {
              id: "Volkswagen",
              label: "Volkswagen",
            },
            {
              id: "Volvo",
              label: "Volvo",
            },
          ],
          header: {
            children: "What car brand would make a good tour partner?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.entertainment",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.sparetime",
          header: {
            children:
              "Which of these are things you like to do in your spare time?",
          },
          options: [
            {
              id: "teamsports",
              label: "Playing team sports (basketball/soccer/softball, etc.)",
            },
            { id: "bar", label: "Going to a bar" },
            { id: "socialmedia", label: "Checking social media" },
            { id: "golf", label: "Golf" },
            { id: "music", label: "Listening to music" },
            {
              id: "makingmusic",
              label:
                "Playing or creating music (guitar, piano, sequencing, etc.)",
            },
            { id: "gaming", label: "Gaming (console or computer)" },
            { id: "camping", label: "Camping or Fishing" },
            { id: "crafts", label: "Crafts/Sewing/crocheting" },
            { id: "astrology", label: "Astrology" },
            { id: "volunteer", label: "Volunteer/charity work" },
            { id: "createsocialmedia", label: "Creating social media content" },
            { id: "reading", label: "Reading" },
            { id: "tuning", label: "Work on my car/car tuning" },
            { id: "offroad", label: "Off-roading/overlanding" },
            { id: "traveling", label: "Traveling" },
            { id: "home", label: "Home improvement" },
            { id: "yoga", label: "Yoga" },
            { id: "cooking", label: "Cooking/baking" },
            { id: "church", label: "Church/church activities" },
            { id: "biking", label: "Biking" },
            { id: "concert", label: "Going to a concert" },
            { id: "tv", label: "Watching TV/movies/streaming content at home" },
            { id: "movies", label: "Going to the movies" },
            { id: "cardio", label: "Running/doing cardio workouts" },
            { id: "weights", label: "Lifting weights" },
            { id: "podcast", label: "Listen to podcasts" },
            { id: "hiking", label: "Hiking" },
            { id: "watchsports", label: "Watch sports" },
            { id: "photography", label: "Photography" },
            { id: "none", label: "None of the above" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.hobbybrands",
          header: {
            children:
              "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!",
          },
          validation: {
            type: "required",
            message: "Please provide a response",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchproviders",
          header: {
            children:
              "You sit down to watch something, which are you likely to be watching?",
          },
          options: [
            { id: "tv", label: "Traditional TV/cable" },
            { id: "streaming", label: "Streaming Services" },
            { id: "yt", label: "YouTube" },
            { id: "shortform", label: "Short Form Video" },
            { id: "livestream", label: "Twitch or live video platform" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchtech",
          header: {
            children: "Which of the following do you have?",
          },
          options: [
            { id: "peloton", label: "A piece of “smart” gym equipment" },
            { id: "crypto", label: "A crypto wallet" },
            { id: "vr", label: "A VR headset" },
            { id: "smartwatch", label: "“Wearable” Tech" },
            { id: "smarthome", label: "“Smart Home” system" },
            { id: "smartspeaker", label: "Smart Speaker" },
            { id: "console", label: "Newest generation console" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
  ],
];

/**
 * The mobile and desktop variants for a text module type
 *
 * @deprecated We now serialize the media queries/css into the config
 */
const TextVariants = [
  "Body1",
  "Body2",
  "Button",
  "Caption",
  "Headline2",
  "Headline3",
  "Headline4",
  "Label",
  "Subtitle2",
] as const;
export type TextVariant = (typeof TextVariants)[number];

export type Validation = {
  type: string;
  message: string;
  min?: number;
  max?: number;
  all?: string[];
  // list of ids for user provided values
  userValues?: string[];
};

/**
 * to pull up all winner metdata, we need the id of the submission in typesense and
 * the id of the contact in typesense
 */
export interface Winner {
  submissionId: string;
  contactId: string;
}
