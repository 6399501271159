import { FieldValue, Timestamp } from "firebase/firestore";
import {
  SurveyOptIn,
  ProfilingCategory,
  ModuleType,
  TextVariant,
  SurveyStatus,
  SurveyConfigOption,
} from "../fanApp/types";
import { CardVariant } from "../../functions/setfan";

/**
 * path: /set_fan_builder_surveys/{surveyId}
 */
export interface SurveyBuilderRootDocument {
  artistGroupId: string;
  createdAt: Timestamp;

  draftId: string;

  publishedId?: string;
  status: Extract<SurveyStatus, "draft" | "live" | "completed" | "deleted">;
}

/**
 * Serializable version of `SurveyBuilderConfig`.
 *
 * path: /set_fan_builder_surveys/{surveyId}/versions/{versionId}
 */
export interface SurveyBuilderVersionDocument
  extends Omit<SurveyBuilderConfig, "fields"> {
  createdAt?: FieldValue;
  fields: SurveyBuilderFieldsContentOnly;
}

/**
 * Version of `SurveyBuilderFields` with only the "content" property.
 */
export type SurveyBuilderFieldsContentOnly = {
  [K in keyof SurveyBuilderFields]: {
    content: SurveyBuilderFields[K]["content"];
  };
};

/**
 * Survey config used in the UI
 */
export type SurveyBuilderConfig = {
  artistGroupId: string;
  artistPolicy?: string;
  fields: SurveyBuilderFields;
  optIns?: SurveyBuilderOptIn[];
  surveyQuestions: BuilderConfigModule[];
  flags?: SurveyBuilderFlags;
};
export type SurveyBuilderOptIn = SurveyOptIn & { separate?: boolean };

export type TranslateableModuleContent = {
  [K in SurveyLanguageCode]?: string;
} & { en: string };

export const SurveyLanguages = {
  es: "Spanish",
  fr: "French",
  de: "German",
} as const;

export type SurveyBuilderFlags = {
  showContestConfirmationMessage: Nullable<boolean>;
};

export type SurveyLanguageCode = keyof typeof SurveyLanguages | "en";

export type SurveyLanguageName =
  (typeof SurveyLanguages)[keyof typeof SurveyLanguages];

export type SurveyTranslatableContent<T = string> = {
  [index in SurveyLanguageCode]?: T;
} & {
  en: T;
};

export type LayoutContent = "default" | "angular" | "wave" | "boxy";

export type PhoneNumberValidation = "required" | "optional" | "no-phone";

export type TextureContent =
  | "none"
  | "distressed"
  | "lights"
  | "watercolor"
  | "waves"
  | "paper"
  | "water"
  | "geometric";

type Nullable<T> = T | null;

export interface SurveyBuilderFields {
  description: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  header: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  body: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  image: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  backgroundImage: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  backgroundColor: {
    content: string;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  buttonColor: {
    content: string;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  layout: {
    content: LayoutContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  texture: {
    content: TextureContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  darkMode: {
    content: boolean;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  isContest: {
    content: boolean;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  contestPrize: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  contestPrizeValue: {
    content: Nullable<number>;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  contestEndDate: {
    content: Nullable<Timestamp>;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  contestCustomPrizeDescription: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  hasContestCustomRules: {
    content: Nullable<boolean>;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  contestCustomRulesUrl: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  numberOfCategories: {
    content: string;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  displayedCategories: {
    content: CategoryOptionId[];
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  demographicsIntroText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  fooddrinkIntroText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  alcoholIntroText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  clothingIntroText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  vehicleIntroText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  entertainmentIntroText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  phoneNumberValidation: {
    content: Nullable<PhoneNumberValidation>;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  thankYouHeader: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  thankYouBody: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  hasFeaturedCta: {
    content: Nullable<boolean>;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: false;
    isDirty: boolean;
    isTouched: boolean;
  };
  featuredCtaHeaderText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  featuredCtaButtonText: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
  featuredCtaUrl: {
    content: SurveyTranslatableContent;
    getValidation: (b: SurveyBuilderConfig) => string | false;
    isTranslateable: true;
    isDirty: boolean;
    isTouched: boolean;
  };
}

export type BuilderConfigOption = {
  id?: string;
  label?: TranslateableModuleContent;
  altLabel?: TranslateableModuleContent;
  src?: TranslateableModuleContent;
  htmlLabel?: TranslateableModuleContent;
  // for user defined single and multi select options
  userDefined?: boolean;
  // for songs questions
  options?: BuilderConfigOption[];
};

type BaseBuilderConfigModule = {
  id?: string;
  noBorder?: boolean;
  style?: CSSServerProperties;
  tag?: string;
  type: ModuleType;
  actionRequired?: boolean;
};

export type LabeledBuilderConfigModule = BaseBuilderConfigModule & {
  label: TranslateableModuleContent;
};

export type InputBuilderConfigModule = LabeledBuilderConfigModule & {
  required: boolean;
};

export type SelectBuilderConfigModule = InputBuilderConfigModule & {
  options?: BuilderConfigOption[];
  randomizeOptions?: boolean;
};

export type CardBuilderConfigModule = BaseBuilderConfigModule & {
  backgroundColor?: string;
  compact: boolean;
  image?: { src: string; align: "left" | "center" };
  modules?: BuilderConfigModule[];
  url?: string;
  variant: CardVariant;
};

export type PageBuilderConfigModule = BaseBuilderConfigModule & {
  modules: BuilderConfigModule[];
  compact: boolean;
};

export type ProgressBuilderConfigModule = BaseBuilderConfigModule;

export type ContainerBuilderConfigModule = BaseBuilderConfigModule & {
  modules: BuilderConfigModule[];
};

export type ImageBuilderConfigModule = LabeledBuilderConfigModule & {
  src?: TranslateableModuleContent;
};

export type ImageQuestionBuilderConfigModule = SelectBuilderConfigModule & {
  showLabels?: boolean;
};

export type MultiSelectBuilderConfigModule = Omit<
  SelectBuilderConfigModule,
  "required"
> & {
  requiredMin?: number;
  requiredMax?: number;
};

export type ProfilingBuilderConfigModule = BaseBuilderConfigModule & {
  index: number;
  force?: ProfilingCategory;
};

export type RankedBuilderConfigModule = InputBuilderConfigModule & {
  displayOrder?: "asc" | "desc";

  /**
   * the label to be appended to the "lowest" option.
   * eg. rankLow: "least", label for option 1 would be `1 (least)`
   */
  rankLow: TranslateableModuleContent;

  /**
   * the label to be appended to the "highest" option.
   * eg. rankHigh: "most", rankRange: 5, label for option 5 would be `5 (most)`
   */
  rankHigh: TranslateableModuleContent;

  /**
   * the maximum value for the ranked question
   */
  rankRange: number;
};

export type SingleSelectBuilderConfigModule = SelectBuilderConfigModule & {
  /**
   * we use this flag to keep a module from being shown to the user and from being validated on submit.
   * currently, this is only useable on single select modules since we use it to inject eventIds into
   * the survey form opaquely
   */
  hidden?: boolean;
};

export type SocialMediaBuilderConfigModule = BaseBuilderConfigModule & {
  // the color of social anchor tags
  color?: string;
  facebook?: string;
  instagram?: string;
  snapchat?: string;
  tiktok?: string;
  twitter?: string;
};

export type SongsQuestionBuilderConfigModule = SelectBuilderConfigModule & {
  requiredMax?: number;
};

export type SubmitBuilderConfigModule = BaseBuilderConfigModule & {
  href?: TranslateableModuleContent;
  label: TranslateableModuleContent;
  complete: boolean;
};

export type TextBuilderConfigModule = LabeledBuilderConfigModule & {
  caption?: TranslateableModuleContent;
  variants: { mobile: TextVariant; desktop: TextVariant };
};

export type TextQuestionBuilderConfigModule = InputBuilderConfigModule & {
  multiline?: boolean;
};

export type BuilderConfigModule =
  | BaseBuilderConfigModule
  | LabeledBuilderConfigModule
  | InputBuilderConfigModule
  | SelectBuilderConfigModule
  | CardBuilderConfigModule
  | PageBuilderConfigModule
  | ContainerBuilderConfigModule
  | ImageBuilderConfigModule
  | ImageQuestionBuilderConfigModule
  | MultiSelectBuilderConfigModule
  | ProfilingBuilderConfigModule
  | RankedBuilderConfigModule
  | SingleSelectBuilderConfigModule
  | SongsQuestionBuilderConfigModule
  | SubmitBuilderConfigModule
  | TextBuilderConfigModule
  | TextQuestionBuilderConfigModule;

type RemovePrefix<
  P extends string,
  S extends string,
> = S extends `${P}.${infer T}` ? T : never;

export const CATEGORIES_OPTIONS_IDS: readonly RemovePrefix<
  "profilecategory",
  Exclude<ProfilingCategory, "profilecategory.email" | "profilecategory.core">
>[] = [
  "demographics",
  "fooddrink",
  "alcohol",
  "clothing",
  "vehicle",
  "entertainment",
] as const;

export type CategoryOptionId = (typeof CATEGORIES_OPTIONS_IDS)[number];

export type CSSServerProperties = Record<string, unknown>;

export interface QuestionEditProps {
  options?: SurveyConfigOption[];
  question: string;
  setlist?: { requiredMax: number };
  image?: { src: string; label?: string };
  rank?: { low: string; high: string; range: number };
  text?: { caption?: string };
}
