import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { TextInput } from "melodies-source/TextInput";

export const ZipQuestion = ({
  id,
  style,
  header,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;
  const isUsa = !(
    !!data?.countryCode?.value && data?.countryCode?.value !== "US"
  );
  const handleChange = (text) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: text,
      },
    });
  };
  const valid = useValidation(id, validation);

  return (
    <QuestionContainer noBorder>
      <HeadlineText {...header} children={isUsa ? "Zip Code" : "Postal Code"} />
      <TextInput
        type="email"
        value={d?.value || ""}
        onChange={handleChange}
        style={{ ...style, marginTop: 10 }}
        placeholder={isUsa ? "Zip Code" : "Postal Code"}
      />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
