import {
  Module,
  useDataContext,
  shuffleArray,
  QuestionContainer,
  useSurveyContext,
} from "Components";
import { useEffect } from "react";
import { SurveyConfigModule, SurveyConfigProfilingModule } from "@max/common/setfan";

let shared = [];

export const Profiling = ({
  id,
  index = 0,
  style,
  header,
  options,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const {
    survey,
    id: surveyId,
    email,
    isEmailInjected,
    progressiveProfiling,
    loadingProfiling,
  } = useSurveyContext();
  const { data, setData } = useDataContext();

  useEffect(() => {
    if (!shared[index] && !loadingProfiling) {
      let submittedKeys = Object.entries(progressiveProfiling || {})?.reduce(
        (acc, [key, val]) => {
          if (key.startsWith("profilecategory")) {
            acc[key] = val;
          }
          return acc;
        },
        {},
      );
      //don't ask email if we already have it
      if (email) {
        submittedKeys["profilecategory.email"] = true;
      }

      //don't ask alcohol if they're under 21
      if (!data?.["profiling.ofAge"]?.value) {
        submittedKeys["profilecategory.alcohol"] = true;
      }

      let selectedCategory =
        props.force ||
        shared[index] ||
        data?.[`profiling.selectedCategory.${surveyId}`]?.value[index];
      const otherSelections = data?.[`profiling.selectedCategory.${surveyId}`]
        ?.value as string[];
      if (otherSelections) {
        otherSelections.forEach((k, idx) => {
          if (k && idx !== index) {
            shared[idx] = k;
          }
        });
      }
      shared.forEach((k, idx) => {
        if (idx !== index) {
          submittedKeys[k] = true;
        }
      });
      if (!selectedCategory) {
        survey?.localProfiling.find((priority) => {
          return shuffleArray(priority).find(
            (category: SurveyConfigProfilingModule) => {
              if (!submittedKeys[category.id]) {
                selectedCategory = category.id;
                return true;
              }
              return false;
            },
          );
        });
      }
      if (!selectedCategory) {
        selectedCategory = "profilecategory.completed"; //we asked everything
      }
      let newval = [...shared];
      newval.push(selectedCategory);
      shared = newval;
      const segments = shared.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: { value: true },
        }),
        {},
      );
      setData({
        ...data,
        [`profiling.selectedCategory.${surveyId}`]: {
          value: newval,
        },
        ...segments,
      });
    }
  }, [
    email,
    index,
    props.force,
    survey?.localProfiling,
    loadingProfiling,
    surveyId,
    progressiveProfiling,
  ]);

  const profileCategory = ((): SurveyConfigProfilingModule | undefined => {
    if (
      data[`profiling.selectedCategory.${surveyId}`]?.value[index] ===
      "profilecategory.completed"
    ) {
      return defaultCategory(surveyId) as SurveyConfigProfilingModule;
    }

    // hide email if forced and already answered (must be in progressive profiling data)
    if (props.force === "profilecategory.email") {
      if (progressiveProfiling?.email && isEmailInjected) return undefined;
      else {
        const forcedCategory = survey.localProfiling?.find((prof) => {
          return prof.find((p) => p.id === props.force);
        });
        return forcedCategory?.[0] ?? undefined;
      }
    }

    let cat: SurveyConfigProfilingModule = undefined;

    survey?.localProfiling?.find((category) =>
      category.find((q) => {
        if (
          q.id === data[`profiling.selectedCategory.${surveyId}`]?.value[index]
        ) {
          cat = q;
          return true;
        }
        return false;
      }),
    );
    return cat;
  })();

  return (
    <>
      {profileCategory?.preface?.map((q) => {
        return (
          <QuestionContainer
            noBorder
            key={`profile-category-preface-${q.content.slice(0, 10)}`}
          >
            <Module {...q} />
          </QuestionContainer>
        );
      })}
      {profileCategory?.questions?.map((q) => {
        return (
          <Module
            {...{
              ...q,
              header: {
                children: q.header?.children?.replaceAll(
                  "{artist}",
                  survey.artistName,
                ),
              },
            }}
            key={`profile-category-question-${q.id}`}
          />
        );
      })}
    </>
  );
};

const defaultCategory = (surveyId: string) => ({
  id: "profilecategory.completed",
  questions: [
    {
      type: "TextQuestion",
      header: { children: "What would you like to tell us?" },
      id: `profilecategory.completed.${surveyId}`,
    },
  ],
});
