import { CSSServerProperties } from ".";
import { CardVariant } from "../../functions/setfan";
import { CardModule, ContainerModule } from "../modules";
import { SurveyModule } from "../modules/base";

export const SurveyContainer = (
  modules: SurveyModule[],
  type: "header" | "body",
  style?: CSSServerProperties,
): ContainerModule =>
  new ContainerModule({
    modules,
    style:
      type === "header"
        ? { marginBottom: 30, maxWidth: "660px", width: "100%", ...style }
        : { maxWidth: 660, width: "100%", ...style },
  });

export const SurveyCard = ({
  modules,
  variant,
  image,
  compact,
  style,
  url,
}: {
  modules: SurveyModule[];
  image?: {
    src: string;
    align: "left" | "center";
    style?: CSSServerProperties;
  };
  variant: CardVariant;
  compact: boolean;
  style?: CSSServerProperties;
  url?: string;
}): CardModule =>
  new CardModule({
    compact,
    image,
    url,
    backgroundColor: "#FAFBFB",
    variant,
    style,
    modules,
  });
