import { useEffect, useState } from "react";
import {
  useDataContext,
  Module,
  Footer,
  Privacy,
  Terms,
  useSurveyContext,
} from "Components";
import { Helmet } from "react-helmet";
import { Header } from "Components/Header";

interface ModalStateProps {
  privacy?: boolean;
  terms?: boolean;
}
export const App = () => {
  const { survey } = useSurveyContext();

  const { view } = useDataContext();
  const [modalState, setModalState] = useState<ModalStateProps>({});

  const showTerms = () => {
    setModalState({ terms: true });
  };

  const showPrivacy = () => {
    setModalState({ privacy: true });
  };

  useEffect(() => {
    document.addEventListener("setfan-show-terms", showTerms);
    document.addEventListener("setfan-show-privacy", showPrivacy);
    return () => {
      document.removeEventListener("setfan-show-terms", showTerms);
      document.removeEventListener("setfan-show-privacy", showPrivacy);
    };
  }, []);

  const handleModalState = (val: string) => {
    setModalState({ [val]: true });
  };

  return (
    <>
      {modalState?.privacy && (
        <Privacy
          onClose={() => setModalState({})}
          setModal={handleModalState}
        />
      )}
      {modalState?.terms && <Terms onClose={() => setModalState({})} />}
      {survey.meta && (
        <Helmet>
          {survey.meta.map((k, idx) => {
            const Tag = Object.keys(k)[0];
            const { props, value } = k[Tag];
            return (
              <Tag {...props} key={`tag-${idx}`}>
                {value}
              </Tag>
            );
          })}
        </Helmet>
      )}
      <Header />
      <Module {...survey.pages[view]} />
      <Footer setModal={handleModalState} />
    </>
  );
};
