import { MutableRefObject, useEffect } from "react";

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const groupToArrays = (groupedCategories: { group: number }[]) =>
  groupedCategories
    .reduce((acc, curr) => {
      if (!acc[curr.group]) {
        acc[curr.group] = [curr];
      } else {
        acc[curr.group].push(curr);
      }
      return acc;
    }, [])
    // might have gaps in groups depending on profiling exclusions list.
    .filter((cat) => !!cat);

export const useClickOutside = (
  ref: MutableRefObject<HTMLElement>,
  onClickOutside: Function,
  exclusionArea?: MutableRefObject<HTMLElement>
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref?.current &&
        exclusionArea?.current.contains(event.target as Node)
      ) {
        return;
      }
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        onClickOutside(event);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
