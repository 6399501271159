import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  DataProvider,
  SurveyProvider,
  AnalyticsProvider,
  ApplyGlobalStyles,
} from "Components";
import { Theme } from "melodies-source/Theme";
import { AuthProvider } from "Components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Spinner } from "melodies-source/Spinner";

const Home = React.lazy(() => import("Routes/Home"));

if (process.env.REACT_APP_ENV !== "dev") {
  LogRocket.init("fyqxo2/set-fan");
}
setupLogRocketReact(LogRocket);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Theme>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <Spinner style={{ minHeight: "100vh" }} color="#d3d3d3" />
                  }
                >
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/:surveyId"
              element={
                <AuthProvider>
                  <SurveyProvider>
                    <ApplyGlobalStyles />
                    <DataProvider>
                      <AnalyticsProvider>
                        <App />
                      </AnalyticsProvider>
                    </DataProvider>
                  </SurveyProvider>
                </AuthProvider>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Theme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
