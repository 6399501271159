import { SurveyConfigModule, ModuleType } from "../fanApp/types";
import { generateUUID } from "./util";
import {
  BuilderConfigModule,
  BuilderConfigOption,
  CSSServerProperties,
  QuestionEditProps,
  TranslateableModuleContent,
} from "../creator";
import { LanguageCode } from "../../creator";

/**
 * this file contains abstract classes used as base classes for final survey modules
 */

export type SurveyModuleProps = {
  actionRequired?: boolean;
  id?: string;
  languageCode?: LanguageCode | "en";
  noBorder?: boolean;
  style?: CSSServerProperties;
  tag?: string;
};

export abstract class SurveyModule {
  protected abstract _type: ModuleType;
  private _languageCode: LanguageCode | "en";
  private _id: string;
  noBorder: boolean;
  style: CSSServerProperties;
  tag?: string;
  actionRequired?: boolean;

  constructor({
    actionRequired = false,
    id = generateUUID(),
    languageCode = "en",
    noBorder = false,
    style = {},
    tag,
  }: SurveyModuleProps) {
    this.actionRequired = actionRequired;
    this._id = id;
    this.noBorder = noBorder;
    this.style = style;
    this._languageCode = languageCode;

    if (tag) {
      this.tag = tag;
    }
  }

  get id(): string {
    return this._id;
  }

  get type(): ModuleType {
    return this._type;
  }

  get languageCode() {
    return this._languageCode;
  }

  set languageCode(langCode) {
    this._languageCode = langCode;
  }

  abstract validate(): boolean;

  abstract toBuilderConfig(): BuilderConfigModule;

  abstract toSurveyConfig(): SurveyConfigModule | SurveyConfigModule[];

  abstract editProps(): QuestionEditProps | undefined;
}

export type LabeledModuleProps = SurveyModuleProps & {
  label?: TranslateableModuleContent;
};
export abstract class LabeledModule extends SurveyModule {
  label: TranslateableModuleContent;

  constructor({ label = { en: "" }, ...rest }: LabeledModuleProps) {
    super(rest);

    this.label = label;
  }
}

export type InputModuleProps = LabeledModuleProps & { required?: boolean };
export abstract class InputModule extends LabeledModule {
  required: boolean;

  constructor({ required = true, ...rest }: InputModuleProps) {
    super(rest);

    this.required = required;
  }
}

export type SelectModuleProps = InputModuleProps & {
  options?: BuilderConfigOption[];
  randomizeOptions?: boolean;
};
export abstract class SelectModule extends InputModule {
  options: BuilderConfigOption[];
  randomizeOptions: boolean;

  constructor({
    options = [],
    randomizeOptions = false,
    ...rest
  }: SelectModuleProps) {
    super(rest);

    this.options = options;
    this.randomizeOptions = randomizeOptions;
  }
}

export const INVALID_MODULE = <T extends SurveyModule>(mod: T) =>
  `unable to validate ${mod.type} module`;
