import {
  SurveyConfigModule,
  SurveyConfigProfilingModule,
  Validation,
} from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import { useDataContext } from "Components";

export const useRule = (r) => {
  const { data } = useDataContext();
  if (r) {
    if (r.any) {
      return r.any.find((rule) => checkRule(rule, data));
    }
    if (r.all) {
      return r.all.reduce((acc, rule) => acc && checkRule(rule, data), true);
    }
  }
  return true;
};

export const checkRule = ({ type, id, value }, data) => {
  switch (type) {
    case "questionAnswered":
      if (value?.any) {
        if (typeof data?.[id]?.value !== "object") {
          return value?.any.includes(data?.[id]?.value);
        }
        return data?.[id]?.value?.find((v) => value?.any.includes(v));
      }
      if (value?.all) {
        if (typeof data?.[id]?.value !== "object") {
          return !value.all.find((v) => !data?.[id]?.value.includes(v));
        }
        return data?.[id]?.value?.find((v) => !value?.all.includes(v));
      }
      break;
    default:
      return true;
  }
};

export const useValidation = (id: string, validation: Validation) => {
  const { data, showValidation } = useDataContext();
  return showValidation ? validate(id, validation, data) : false;
};

export const validate = (
  id: string,
  validation: Validation,
  data: SurveyResponse,
) => {
  if (validation) {
    switch (validation.type) {
      case "required": {
        if (validation.userValues?.includes(data?.[id]?.value)) {
          return !!data?.[id]?.userValue?.[data[id].value]
            ? true
            : validation.message;
        }

        return typeof data?.[id]?.value === "object"
          ? !!data[id].value[0]
            ? true
            : validation.message
          : !!data?.[id]?.value
          ? true
          : validation.message;
      }
      case "require_count": {
        if (!Array.isArray(data?.[id]?.value)) {
          return "This field is required";
        }

        let countAnswered = 0;

        if (validation.userValues?.length) {
          // figure out which userValue ids were filled in
          validation.userValues.forEach((userValId) => {
            if (data?.[id]?.userValue?.[userValId]) {
              countAnswered++;
            }
          });
        }

        // filter userValues since counted above
        countAnswered +=
          data?.[id]?.value.filter((id) => !validation.userValues?.includes(id))
            ?.length ?? 0;

        if (
          validation.min !== undefined &&
          countAnswered < Number(validation.min)
        ) {
          return `Please select at least ${validation.min} option${
            validation.min === 1 ? "" : "s"
          }.`;
        }

        if (
          validation.max !== undefined &&
          countAnswered > Number(validation.max)
        ) {
          return `Please select a maximum of ${validation.max} option${
            validation.max === 1 ? "" : "s"
          }.`;
        }

        return true;
      }
      case "require_all": {
        const isValid = validation.all.reduce((acc, curr) => {
          acc = acc && data?.[id]?.value.includes(curr);
          return acc;
        }, true);
        return isValid || validation.message;
      }
      case "name":
        return data?.[id]?.value[0] && data?.[id]?.value[1]
          ? true
          : validation.message;
      case "email":
        return /(.+)@(.+){2,}\.(.+){2,}/.test(data?.[id]?.value)
          ? true
          : validation.message;
      case "yearborn":
        return !data?.[id]?.value
          ? "Please enter your birth year"
          : /^(?:19|20)\d{2}$/.test(data?.[id]?.value)
          ? true
          : validation.message;
      case "zip":
        const isUsa = !(
          !!(data as { countryCode?: { value: string } })?.countryCode?.value &&
          (data as { countryCode?: { value: string } })?.countryCode?.value !==
            "US"
        );
        return isUsa
          ? /^\d{5}(?:[-\s]\d{4})?$/.test(data?.[id]?.value)
            ? true
            : validation.message
          : true;
      default:
        return true;
    }
  }
  return true;
};

export const validateModule = (
  module: SurveyConfigModule,
  data: SurveyResponse,
  surveyId: string,
  profiling: SurveyConfigProfilingModule[][],
) => {
  let val;
  if (module.type === "profiling") {
    const selectedCategory =
      data?.[`profiling.selectedCategory.${surveyId}`]?.value?.[module.index];
    if (selectedCategory) {
      let cate: SurveyConfigProfilingModule;
      profiling.find((category) => {
        return category.find((q) => {
          if (q.id === selectedCategory) {
            cate = q;
            return true;
          }
          return false;
        });
      });
      val = !cate?.questions.find(
        (q) => !validateModule(q, data, surveyId, profiling),
      );
    } else {
      val = true;
    }
  } else {
    val = validate(module.id, module.validation, data);
  }
  if (typeof val === "string") {
    return false;
  }
  return (
    val &&
    !module.modules?.find((m) => !validateModule(m, data, surveyId, profiling))
  );
};
